/* import Google font */
@import url('https://fonts.googleapis.com/css2?family=Over+the+Rainbow&family=Shadows+Into+Light&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Catamaran:wght@300;400;500;600;700&family=Over+the+Rainbow&family=Shadows+Into+Light&display=swap');


:root {
  --body-background:#fff;
  /* nav */
  --nav-background: hsl(220, 29%, 31%);
  /* connect */
  --connect-icon:#acb1be;
  --connect-icon-hover:hsl(0, 0%, 100%);
}

.btn-dark {
  background-color: var(--nav-background);
}

.btn-dark:hover {
  background-color: hsl(220, 29%, 41%);
}

body {
  font-family: 'Catamaran', sans-serif;
  background-color: var(--body-background);
}

.navbar {
  background-color: var(--nav-background);
}

#navLogo {
  font-family: 'Over the Rainbow', cursive;
  /* font-family: 'Shadows Into Light', cursive; */
}


.project-item {
  text-align: left;
}

.project-image1,
.project-image2,
.project-image3,
.project-image4,
.project-image5,
.project-image6,
.project-image7 {
  background-size:cover;
  background-repeat: no-repeat;
  background-position-x: center;
  height: 600px;
}


  .project-image1 {
    background-image: url("/src/assets/images/prevena-demo-cover-mobile.jpg");
}

@media screen and (min-width:768px) {
  .project-image1 {
    background-image: url("/src/assets/images/prevena-demo-cover-desktop.jpg");
  }
}

.project-image2 {
  background-image: url("/src/assets/images/prevena-central-home-cover-mobile.jpg");
}

@media screen and (min-width:768px) {
.project-image2 {
  background-image: url("/src/assets/images/prevena-central-home-cover-desktop.jpg");
}
}
.project-image3 {
  background-image: url("/src/assets/images/noyes-mobile.jpg");
}

@media screen and (min-width:768px) {
.project-image3 {
  background-image: url("/src/assets/images/noyes-desktop.jpg");
}
}
.project-image4 {
  background-image: url("/src/assets/images/readmeGenerator.jpg");
}
.project-image5 {
  background-image: url("/src/assets/images/employeeTracker.jpg");
}
.project-image6 {
  background-image: url("/src/assets/images/teamProfileGenerator.jpg");
}
.project-image7 {
  background-image: url("/src/assets/images/javascript-quiz.jpg");
}


/* MAIN BODY */
.jumbotron {
  color: black;
  background-image: url("/src/assets/images/hero.jpeg");
  background-position: center;
  /* background-position-x: 3%; */
  background-repeat: no-repeat;
  background-size: contain;
  height: 200px;
}

.connect-icon {
  color: var(--connect-icon);
}

.connect-icon:hover {
  color: var(--connect-icon-hover);
}

.footer {
  background-color: var(--nav-background);
  color: #acb1bd;
}

.nowrap {
  word-wrap: nowrap;
}

/* Contact */
.input-message {
  height:200px;
  padding-left: 3px;
}

/* RESUME PAGE */
#resume-frame {
  height: 90vh;
}

.download-pdf {
  color: var(--nav-background);
}
.download-pdf:hover {
  color: hsl(220, 29%, 51%);
}

.aboutContainer {
  height: 100%;
}

@media screen and (max-width:768px) {
  
  .project-image1,
  .project-image2,
  .project-image3,
  .project-image4,
  .project-image5,
  .project-image6,
  .project-image7 {
  height: 520px;
}

}
/* PROJECT PAGES */

@media screen and (max-width:767px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: block;
  }
  }

  @media screen and (min-width:768px) {
    .mobile-only {
      display: none;
    }
    .desktop-only {
      display: block;
    }
    }

  


